import React from "react"
import {graphql, Link} from 'gatsby';
import Layout from '../components/layout';
import {GatsbySeo} from 'gatsby-plugin-next-seo/src/meta/gatsby-seo';
import useSiteMetadata from '../hooks/use-site-metadata';


const BookPage = ({ data, location }) => {
    const posts = data.allMarkdownRemark.nodes
    const book = data.markdownRemark

    const { siteUrl, defaultImage } = useSiteMetadata();
    const ogImageUrl = `${siteUrl}${(book.frontmatter.featuredImage && book.frontmatter.featuredImage?.childImageSharp.fluid.src) || defaultImage}`;

    return (
        <Layout>
            <GatsbySeo
                title={book.frontmatter.title}
                description={book.excerpt}
                openGraph={{
                    url: `${siteUrl}${location.pathname}`,
                    title: `${book.frontmatter.title}`,
                    images: [
                        {
                            url: ogImageUrl,
                            width: 1200,
                            height: 630
                        }
                    ]
                }}
            />
            <section id="page-title">

                <div className="container clearfix">
                    <h1>{book.frontmatter.title}</h1>
                    <span>{`${book.frontmatter.author} - ${book.frontmatter.year}`}</span>
                    {/*<ol className="breadcrumb">*/}
                    {/*    <li className="breadcrumb-item"><a href="#">Home</a></li>*/}
                    {/*    <li className="breadcrumb-item active" aria-current="page">Blog</li>*/}
                    {/*</ol>*/}
                </div>

            </section>
            <section id="content">
                <div  className="content-wrap">
                    <div  className="container clearfix">

                        <div  className="row gutter-40 col-mb-80">
                            <div  className="postcontent col-lg-9">

                                <div className="fancy-title title-bottom-border">
                                    <h4>Önsöz</h4>
                                </div>

                                <section
                                    dangerouslySetInnerHTML={{ __html: book.html }}
                                />

                            </div>

                            <div  className="sidebar col-lg-3">
                                <div  className="sidebar-widgets-wrap">

                                    {posts.length > 0 &&
                                    <div  className="widget widget_links clearfix">

                                        <h4>İçindekiler</h4>
                                        <ul>
                                            {posts.map(post => {
                                                return (
                                                    <li key={post.frontmatter.slug}><Link activeClassName="current" to={`/${post.frontmatter.slug}`}>{post.frontmatter.title}</Link></li>
                                                )
                                            })}
                                        </ul>

                                    </div>
                                    }
                                    <div className="widget clearfix">
                                        <h4>{book.frontmatter.title}</h4>
                                        <img src={book.frontmatter.featuredImage?.childImageSharp.fluid.src} style={{maxHeight: '300px', borderRadius: '3px'}} alt={`${book.frontmatter.title} - ${book.frontmatter.author}`}/>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default BookPage

export const pageQuery = graphql`
  query (
    $id: String!
    $filterRegex: String
  ) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        year
        author
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: $filterRegex  }}
        sort: { fields: [frontmatter___no], order: ASC }
        ) {
      nodes {
        excerpt
        frontmatter {
          title
          slug
          no
        }
      }
    }
  }
`
